// @ts-strict-ignore
import { AppAuthorizationState } from 'AppRoot';
import { LoadingSpinner, ToastEmitter } from 'components';
import { SecurityChartsForMarketTimeManager } from 'components/Charting/MarketTimeChartManagers';
import { DebugModal } from 'components/DebugModal/DebugModal';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { Flex } from 'components/Flex';
import { ContentBaseLayout } from 'components/Layouts/ContentBaseLayout';
import { FooterBaseLayout } from 'components/Layouts/FooterBaseLayout';
import { HeaderBaseLayout } from 'components/Layouts/HeaderBaseLayout';
import { NewMiniplayer } from 'components/Miniplayer/NewMiniplayer';
import { AmexCardModal } from 'components/Modals/AmexCardModal';
import { PremiumUpgradeModal } from 'components/Modals/PremiumUpgradeModal';
import SidePanel from 'components/Modals/SidePanel';
import { VideoPlayer } from 'components/OneProNetwork/components/videoPlayer/videoPlayer';
import 'components/TradingViewMarquee/TradingViewMarquee.css';
import { WebToken } from 'components/WebToken/WebToken';
import { WelcomeModal } from 'components/WelcomeModal/WelcomeModal';
import { useIsFullscreenRoute } from 'hooks/UseIsFullscreenRoute';
import { useAppWindowSize, WindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { ProductCode } from 'phoenix/constants/PremiumProducts';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useTier } from 'phoenix/hooks/UseTier';
import { CheckLoginStatusAction } from 'phoenix/redux/actions';
import { DebugDumpManager } from 'phoenix/util/DebugDumpManager';
import { ScrollPageToTop } from 'phoenix/util/Scroll';
import { GetVariant } from 'phoenix/util/Variant';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes as Switch, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { Routes, SupportPage, SupportSubroute } from 'util/Routes';
import { AlexDebugScreen } from './AlexDebugScreen';
import DeniedScreen from './DeniedScreen/DeniedScreen';
import FuturesTimeSpreadDeeperView from './FuturesTimeSpreadDeeperView';
import { LocalStartScreen } from './LocalStartScreen/LocalStartScreen';
import LoggedOutScreen from './LoggedOutScreen/LoggedOutScreen';
import { MattDebugScreen } from './MattDebugScreen';
import { NeilDebugScreen } from './NeilDebugScreen';
import { AccountOnboardingSelectionScreen } from './OnboardingScreens/AccountOnboardingSelectScreen';
import { FullyPaidLendingOnboarding } from './OnboardingScreens/FullyPaidLending/Base';
import { FuturesOnboarding } from './OnboardingScreens/Futures/Base';
import { RegistrationOnboarding } from './OnboardingScreens/Registration/Base';
import { OneProAuthorScreen } from './OneProNetworkScreens/oneProAuthorScreen';
import { OneProNetworkScreen } from './OneProNetworkScreens/oneProNetworkScreen';
import { OptionContractScreen } from './OptionContractScreen';
import { RomanDebugScreen } from './RomanDebugScreen';
import { SectorScreen } from './SectorScreen/SectorScreen';
import { YuliDebugScreen } from './YuliDebugScreen';
import { ZanderDebugScreen } from './ZanderDebugScreen';

const WatchlistScreen = React.lazy(() => import('./WatchlistScreen'));
const DocumentsScreen = React.lazy(() => import('./DocumentsScreen/DocumentsScreen'));
const OptionsScreen = React.lazy(() => import('./OptionsScreen/OptionsScreen'));
const OrdersScreen = React.lazy(() => import('./OrdersScreen/OrdersScreen'));
const PortfolioScreen = React.lazy(() => import('./PortfolioScreen/PortfolioScreen'));
const ProfileScreen = React.lazy(() => import('./ProfileScreen/ProfileScreen'));
const RoutedSecurityScreen = React.lazy(() => import('./RoutedSecurityScreen/RoutedSecurityScreen'));
const TransactionsScreen = React.lazy(() => import('./TransactionsScreen/TransactionsScreen'));
const MessagesScreen = React.lazy(() => import('./MessagesScreen/MessagesScreen'));
const ViewMessageScreen = React.lazy(() => import('./ViewMessageScreen/ViewMessageScreen'));
const ProjectedIncomeScreen = React.lazy(() => import('./ProjectedIncomeScreen/ProjectedIncomeScreen'));
const NotFoundScreen = React.lazy(() => import('./NotFoundScreen/NotFoundScreen'));
const FundingScreen = React.lazy(() => import('./FundingScreen/Transfers/FundingScreen'));
const PremiumScreen = React.lazy(() => import('./PremiumScreen/PremiumScreen'));
const DeepAnalysisScreen = React.lazy(() => import('./DeepAnalysisScreen/DeepAnalysisScreen'));
const FuturesDeepAnalysisScreen = React.lazy(() => import('./DeepAnalysisScreen/FuturesDeepAnalysisScreen'));
const DisclosuresScreen = React.lazy(() => import('./DisclosuresScreen/DisclosuresScreen'));
const AdvancedChartScreen = React.lazy(() => import('./AdvancedChartScreen/AdvancedChartScreen'));
const AccountScreen = React.lazy(() => import('./AccountScreen/AccountScreen'));
const PositionsScreen = React.lazy(() => import('./PositionsScreen/PositionsScreen'));
const SupportScreen = React.lazy(() => import('./ProfileScreen/Support/SupportScreen'));
const FuturesSpreadsScreen = React.lazy(() => import('./FuturesSpreadsScreen'));

const RoutingComponent = React.memo(({ appAuthorizationState }: { appAuthorizationState: AppAuthorizationState }) => {
    const [appWindowSize, setAppWindowSize] = useAppWindowSize();

    const dispatch = useDispatch();

    const resizeListener = useCallback(() => {
        if (window.innerWidth <= 1200 && appWindowSize !== WindowSizes.tablet) setAppWindowSize(WindowSizes.tablet);
        else if (window.innerWidth <= 1300 && appWindowSize !== WindowSizes.small) setAppWindowSize(WindowSizes.small);
        else if (window.innerWidth <= 1480 && appWindowSize !== WindowSizes.regular) setAppWindowSize(WindowSizes.regular);
        else if (window.innerWidth > 1480 && appWindowSize !== WindowSizes.large) setAppWindowSize(WindowSizes.large);
    }, [appWindowSize, setAppWindowSize]);
    const isFullScreenRoute = useIsFullscreenRoute();

    useEffect(() => {
        resizeListener();
        window.onresize = resizeListener;
    }, []);

    useEffect(() => {
        dispatch(CheckLoginStatusAction());
    }, [dispatch]);

    const { permittedForDeepAnalysis } = useTier();
    const { showAdvancedChart, showOptions } = GetVariant();
    const showFuturesDeepAnalysis = true;

    const containerRef = useRef<HTMLDivElement>(null);

    const oneProUserPref = useSnexStore((s) => s?.user?.myInfo?.data?.showOneProNetwork);
    const showOnePro = useFeatureFlag(FeatureFlags.OneProNetwork) && oneProUserPref;

    return (
        <>
            <ErrorBoundary>
                <ScrollResetOnNavListener />
            </ErrorBoundary>
            <ErrorBoundary>
                <SecurityChartsForMarketTimeManager />
            </ErrorBoundary>
            <ErrorBoundary>
                <Flex column style={{ marginTop: 0, flex: 1 }}>
                    <Flex column ref={containerRef} fullWidth center style={{ height: '100vh' }}>
                        <HeaderBaseLayout />
                        <React.Suspense
                            fallback={
                                <Flex center style={{ width: '100%', height: '100%' }}>
                                    <LoadingSpinner />
                                </Flex>
                            }
                        >
                            <ContentBaseLayout>
                                <ScreenSwitch
                                    appWindowSize={appWindowSize}
                                    permittedForDeepAnalysis={permittedForDeepAnalysis}
                                    showAdvancedChart={showAdvancedChart}
                                    showOptions={showOptions}
                                    showFuturesDeepAnalysis={showFuturesDeepAnalysis}
                                    showOnePro={showOnePro}
                                />
                            </ContentBaseLayout>
                        </React.Suspense>
                    </Flex>
                    <FooterBaseLayout />
                    <NewMiniplayer />
                    {!isFullScreenRoute && appAuthorizationState === AppAuthorizationState.Allowed ? <WelcomeModal /> : null}
                    {/* <TermsAndConditionsModal /> */}
                    {!permittedForDeepAnalysis && <PremiumUpgradeModal />}
                    <AmexCardModal />
                    <ToastEmitter />
                    <SidePanel />
                    {!isFullScreenRoute && <DebugModal />}
                </Flex>
            </ErrorBoundary>
            {showOnePro && <VideoPlayer />}
        </>
    );
});

export const BaseScreen = React.memo(({ appAuthorizationState }: { appAuthorizationState: AppAuthorizationState }) => {
    return (
        <Flex column style={{ height: '100vh', width: '100%', position: 'relative', overflowY: 'clip' }}>
            <BrowserRouter>
                <ToastProvider autoDismiss autoDismissTimeout={5000} placement='bottom-left'>
                    <RoutingComponent appAuthorizationState={appAuthorizationState} />
                </ToastProvider>
            </BrowserRouter>
        </Flex>
    );
});

const ScrollResetOnNavListener = React.memo(() => {
    const location = useLocation();
    useEffect(() => {
        ScrollPageToTop('instant');
        DebugDumpManager.RecordEvent({ item: location.pathname, event: 'Navigation', other: JSON.stringify(location) });
    }, [location]);

    return null;
});

const ScreenSwitch = React.memo(
    ({
        appWindowSize,
        permittedForDeepAnalysis,
        showAdvancedChart,
        showOptions,
        showFuturesDeepAnalysis,
        showOnePro
    }: {
        appWindowSize: WindowSize;
        permittedForDeepAnalysis: boolean;
        showAdvancedChart: boolean;
        showOptions: boolean;
        showFuturesDeepAnalysis: boolean;
        showOnePro: boolean;
    }) => {
        return (
            <Switch>
                <Route element={<DeniedScreen />} path={Routes.deadLogin()} />
                <Route element={<LoggedOutScreen />} path={Routes.loggedOut()} />
                <Route element={<RegistrationOnboarding />} path={Routes.register(':step')} />
                <Route element={<FullyPaidLendingOnboarding />} path={Routes.fplOnboarding(':step')} />
                <Route element={<AccountOnboardingSelectionScreen />} path={Routes.onboardingSelect()} />
                <Route element={<PortfolioScreen />} path={Routes.portfolio()} />
                <Route element={<FundingScreen />} path={Routes.funding()} />
                <Route element={<AccountScreen />} path={Routes.account(':accountNumber')} />
                <Route element={<RoutedSecurityScreen />} path={Routes.security(':securityId')} />
                <Route element={<OptionContractScreen />} path={Routes.optionContract(':osiSymbol')} />
                <Route element={permittedForDeepAnalysis ? <DeepAnalysisScreen /> : null} path={Routes.deepAnalysis(':symbol')} />
                <Route element={showFuturesDeepAnalysis ? <FuturesDeepAnalysisScreen /> : <NotFoundScreen />} path={Routes.futuresDeepAnalysis(':symbol')} />
                <Route element={showOptions ? <OptionsScreen /> : null} path={Routes.optionChain(':symbol')} />
                <Route element={<FuturesSpreadsScreen />} path={Routes.futuresSpreads(':symbol', ':frontMonthSymbol?')} />
                <Route element={<FuturesTimeSpreadDeeperView />} path={Routes.futuresTimeSpreadDeeperView(':symbol')} />
                <Route element={<ProfileScreen />} path={Routes.profile()} />
                <Route element={<ProfileScreen />} path={Routes.profile(':subroute')} />
                <Route element={<ProfileScreen />} path={Routes.profile(':subroute/:adminSubroute')} />
                <Route element={<DocumentsScreen />} path={Routes.documents()} />
                <Route element={<DocumentsScreen />} path={Routes.documentPreview(':documentTypeId', ':documentId')} />
                <Route element={<PositionsScreen />} path={Routes.positions()} />
                <Route element={<WatchlistScreen />} path={Routes.watchlists(':watchlistId')} />
                <Route element={<OrdersScreen />} path={Routes.orders()} />
                <Route element={<TransactionsScreen appWindowSize={appWindowSize} />} path={Routes.transactions()} />
                <Route element={<MessagesScreen />} path={Routes.messages()} />
                <Route element={<ViewMessageScreen />} path={Routes.message(':messageId')} />
                <Route element={<DisclosuresScreen />} path={Routes.disclosures()} />
                <Route element={<ProjectedIncomeScreen />} path={Routes.projectedIncome()} />
                <Route element={<PremiumScreen />} path={Routes.premium(':productCode' as ProductCode)} />
                <Route element={<WebToken />} path={Routes.oktaToken()} />
                <Route element={showOnePro ? <OneProAuthorScreen /> : <NotFoundScreen />} path={Routes.oneProNetworkAuthor(':authorId')} />
                <Route element={showOnePro ? <OneProNetworkScreen /> : <NotFoundScreen />} path={Routes.oneProNetwork()} />
                <Route element={<AlexDebugScreen />} path={Routes.alexland()} />
                <Route element={<RomanDebugScreen />} path={Routes.romanEmpire()} />
                <Route element={<NeilDebugScreen />} path={Routes.gillyLand()} />
                <Route element={<MattDebugScreen />} path={Routes.mattropolis()} />
                <Route element={<YuliDebugScreen />} path={Routes.yuli()} />
                <Route element={<ZanderDebugScreen />} path={Routes.zanderDebug()} />
                <Route element={<SectorScreen />} path={Routes.sectors(':sector')} />
                <Route element={showAdvancedChart ? <AdvancedChartScreen /> : null} path={Routes.advancedChart(':symbol')} />
                <Route element={<SupportScreen />} path={Routes.support(':subroute' as SupportSubroute, ':page' as SupportPage)} />
                <Route element={<LocalStartScreen />} path={Routes.localLogin()} />
                <Route element={<NotFoundScreen />} path='*' />
            </Switch>
        );
    }
);
